export default class rangeBase extends require('../model'){
	iterate(visitor){

	}
	first(){

	}
	last(){

	}

	static get type(){return 'range'}
}
